<template>
  <div class="spinner spinner-3"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$bg-color: #fff; //container background-color;
$basic-dark-color: #1a4e7c; //color of the spinner
$border-width: 1px; //width of spinners border
$basic-spinner-dimensions: 125px; //width and height of spinner
$main-spinner-dimensions: $basic-spinner-dimensions - $border-width * 2; //width and height of bigger circle
$small-spinner-dimensions: $main-spinner-dimensions * 0.7; //width and height of smaller circle

.spinner {
  position: relative;
  width: $basic-spinner-dimensions;
  height: $basic-spinner-dimensions;
	margin: 0 auto;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
  }
}
.spinner.spinner-3 {
  @keyframes scale-2 {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    50% {
      transform: scale(0.7);
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  &:before {
    width: $main-spinner-dimensions;
    height: $main-spinner-dimensions;
    border-color: $basic-dark-color;
    top: 0px;
    left: 0px;
    animation: scale-2 1.6s linear 0s infinite;
  }

  &:after {
    width: $main-spinner-dimensions;
    height: $main-spinner-dimensions;
    border-color: $basic-dark-color;
    top: 0;
    left: 0;
    opacity: 0;
    animation: scale-2 1.6s linear 0.8s infinite;
  }
}
</style>
