<template>
  <footer>
    <div class="left">Сделано в <a href="https://www.dline-media.com/" target="_blank">DLine Media</a> в 2017 году.</div>
    <div class="center">
      <a
        href="https://vk.com/dline.media"
				class="link-vk"
        target="_blank"
        ><img src="../assets/img/icons/vk.png" /><span>Мы ВКонтакте</span></a
      >
    </div>
    <div class="right">Остались вопросы? Пишите на evgen@dline-media.com</div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
footer {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: auto;
  color: #fff;
  margin: 35px 20px;
  font-weight: 600;
}
footer .left {
  text-align: left;
}
footer .center {
  text-align: left;
}
footer .right {
  text-align: right;
}
footer img {
  height: 20px;
  width: auto;
  object-fit: contain;
  margin-right: 10px;
}
.link-vk {
	display: flex;align-items: center;
}
@media (max-width: 430px) {
	body footer .left {
    grid-area: fleft;
    text-align: center;
    margin-bottom: 10px;
		font-size: 10px;
  }
  body footer .center {
    grid-area: fcenter;
		text-align: center;
		font-size: 10px;
  }
  body footer .right {
    grid-area: fright;
    text-align: center;
		font-size: 10px;
    margin-bottom: 10px;
  }
  body footer {
    grid-template-columns: 1fr;
    grid-template-areas: "fright" "fleft" "fcenter";
  }
	.link-vk {
		justify-content: center;
	}
}
</style>
