<template>
  <div class="info_block">
    <div class="title">
      <h2>Радиостанция {{ data.station_name }}</h2>
      <div></div>
      <div class="controls">
        <Select
          v-if="data.streams.length"
          class="stream"
          v-model="stream"
          :options="data.streams"
          field="id"
          title="Качество {bitrate} kb/s"
        />
        <div
          v-if="stream"
          class="stream__separator"
          style="height: 44px; margin: 0 20px; width: 1px; background: #77b3df"
        ></div>
        <a v-if="stream" :href="host + stream.mount" style="text-decoration: none;" target="_blank">
          <div>
            <img src="../assets/img/icons/headphones.png" alt="" />
            <div class="controls-name">поток</div>
          </div>
        </a>
        <div
          v-if="stream"
          style="height: 44px; margin: 0 20px; width: 1px; background: #77b3df;text-decoration: none;"
        ></div>
        <div v-if="stream" class="stream__copy" @click="copy">
          <img src="../assets/img/icons/copy.png" alt="" />
          <div class="controls-name">скопировать</div>
        </div>
      </div>
    </div>
    <div v-if="stream" class="properties">
      <div>
        <div class="properties-item">
          <img
            class="properties-ico"
            src="../assets/img/icons/sound-frecuency.png"
          />
          <span class="label">Mount:</span>
          <span class="property-value">/{{ stream.mount }}</span>
        </div>
        <div class="properties-item">
          <img
            class="properties-ico"
            src="../assets/img/icons/sound-frecuency.png"
          />
          <span class="label">Битрейт:</span>
          <span class="property-value">{{ stream.bitrate }} kb/s</span>
        </div>
      </div>
      <div>
        <div class="properties-item">
          <img
            class="properties-ico"
            src="../assets/img/icons/speedometer.png"
          />
          <span class="label">Рекорд слушателей:</span>
          <span class="property-value">{{ data.listeners_record }}</span>
        </div>
        <div class="properties-item">
          <img
            class="properties-ico"
            src="../assets/img/icons/teenager-listening-music-with-headphones.png"
          />
          <span class="label">Сейчас слушают:</span>
          <span class="property-value">{{ stream.listeners }}</span>
        </div>
      </div>
    </div>
    <div v-else style="text-align: center; margin-bottom: 30px; color: #979797;">
      Нет потоков
    </div>
    <div class="player-block" v-if="stream">
      <player :id="data.station_id" :streamId="stream.id" :mount="stream.mount" :last-song="getTitle(stream)" />
    </div>
  </div>
</template>

<script>
import Player from "@/components/Player";
import Select from "@/components/Select";

export default {
  components: { Player, Select },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      host: process.env.VUE_APP_AUDIO_HOST + "/",
      stream: this.data.streams[0],
    };
  },
  methods: {
    getTitle(stream) {
      if (stream?.last_song?.title && stream.last_song.title !== "No title") {
        return stream.last_song.title;
      } else {
        return "Нет информации";
      }
    },
    copy() {
      const el = document.createElement("textarea");
      el.value = this.host + this.stream.mount;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$store.commit("notification", {
        image: "",
        title: "Уведомление",
        message: "Вы успешно скопировали ссылку",
        button: "Хорошо",
        show: true,
      });
    },
  },
};
</script>

<style scoped>
.info_block {
  background: #fff;
  border-radius: 7px;
  border: 1px solid #f0f0f0;
  margin-bottom: 25px;
}
.current-track {
  width: 50%;
}
.info_block .title {
  display: grid;
  grid-template-columns: auto 1fr auto;
  background: #ebf2f7;
  padding: 10px 20px;
  position: relative;
  margin-bottom: 30px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border: none;
}
.info_block .title h2 {
  display: flex;
  align-items: center;
  color: #568cbc;
  font-weight: 500;
  font-size: 25px;
  text-align: left;
  margin: 0;
}
.info_block .title .controls {
  display: flex;
  align-items: center;
}
.info_block .title .controls .controls-name {
  font-size: 14px;
  color: #6395c1;
  font-weight: 600;
}
.info_block .title .controls img {
  height: 24px;
  width: auto;
  object-fit: contain;
}

.info_block .properties {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  text-align: left;
  padding: 0 20px 20px;
}
.info_block .properties .properties-item {
  display: flex;
  align-items: center;
  color: #17466f;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px;
}
.info_block .properties .properties-item img {
  height: 24px;
  width: 60px;
  object-fit: contain;
}
.player-block {
  background: #ebf2f7;
  padding: 10px;
}
.link-radio {
  color: rgb(51, 122, 183);
  text-decoration: none;
}
.link-radio:hover {
  cursor: pointer;
  color: rgb(35, 82, 124);
  text-decoration: underline;
}
.label {
  margin-right: 10px;
}

@media (max-width: 700px) {
  .info_block .properties-ico {
    grid-area: properties-ico;
  }
  .info_block .label {
    grid-area: properties-label;
  }
  .info_block .property-value {
    grid-area: property-value;
  }
  .info_block .properties .properties-item {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "properties-ico properties-label" "properties-ico property-value";
  }
  .space {
    display: none;
  }
  .info_block .properties {
    grid-template-columns: 1fr;
  }
}
.stream__copy:hover {
  cursor: pointer !important;
}
@media (max-width: 430px) {
  .controls {
    flex-wrap: wrap;
  }
  .stream {
    width: 100%;
    margin-bottom: 15px;
  }
  .stream__separator {
    display: none;
  }
  .info_block .title h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .current-track {
    width: 100%;
  }
  .info_block .title {
    grid-template-columns: 1fr;
  }
  .info_block .title .controls {
    justify-content: center;
  }
  .info_block .properties {
    grid-template-columns: 1fr;
  }
  .info_block .properties .properties-item {
    font-size: 12px;
  }
  .info_block .properties .properties-item img {
    height: auto;
    width: 20px;
    margin-right: 10px;
    object-fit: contain;
  }
  body .content {
    margin: 35px 0px 0 0px;
    border-radius: 0;
  }
  body .section_header h1 {
    font-size: 25px;
  }
  body .section_header p {
    font-size: 11px;
  }
}
</style>
