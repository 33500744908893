import axios from "axios";

const state = {
  currentTrackTimer: 0,
  currentSong: null,
  player_status: "pausing",
  player_audio: new Audio(),
  player_mount: null,
  station_id: null,
  stream_id: null,
  statistics: [],
  statisticsMeta: {},
};

const currentTrack = () => {
  clearTimeout(state.currentTrackTimer);
  actions.currentSong().finally(() => {
    state.currentTrackTimer = setTimeout(() => {
      currentTrack();
    }, 60000);
  });
};

const mutations = {
  currentSong(state, value) {
    state.currentSong = value;
  },
  currentTrackTimer(state, value) {
    state.currentTrackTimer = value;
  },
  statistics(state, data) {
    if (data?.concat) {
      state.statistics = state.statistics.concat(data.data.data);
      state.statisticsMeta = data.data.meta;
    } else {
      state.statistics = data.data.data;
      state.statisticsMeta = data.data.meta;
    }
  },
  player_status(state, status) {
    state.player_status = status;
    if (state.stream_id && status === "playing") {
      currentTrack();
    } else if (status === "pausing") {
      clearTimeout(state.currentTrackTimer);
    }
  },
  player_mount(state, mount) {
    state.player_mount = mount;
  },
  station_id(state, id) {
    state.station_id = id;
  },
  stream_id(state, id) {
    state.stream_id = id;
  },
};

const actions = {
  async statistics({ commit }, parameters) {
    try {
      let per_page = localStorage.getItem("perPage") || 25; // 25
      let page = 1;
      let concat = false;
      let filter_cluster = "";

      if (parameters?.per_page) {
        per_page = parameters.per_page;
      }
      if (parameters?.page) {
        page = parameters.page;
      }
      if (parameters?.concat) {
        concat = !!parameters.concat;
      }
      
      if (process.env.VUE_APP_SENTRY_ENVIROMENT === "production") {
        filter_cluster = "&filter[cluster_id]=1"
      }
      const response = await axios.get(
        `/statistics?page=${page}&per_page=${per_page}${filter_cluster}`
      );
      commit("statistics", { data: response.data, concat: concat });
      return response.data;
    } catch (e) {}
  },
  async currentSong() {
    if (state.stream_id) {
      state.currentSong = null;
      const response = await axios.get(
        `/streams/${state.stream_id}/current-song`
      );
      let song = response?.data?.data?.title || "No title";
      if (song === "No title") {
        song = "Неизвестно";
      }
      state.currentSong = song;
    }
  },
  async statistic_search({ commit }, parameters) {
    try {
      let filter = "station_name";
      let value = "";

      if (parameters?.filter) {
        filter = parameters.filter;
      }

      if (parameters?.value) {
        value = parameters.value;
      }

      const response = await axios.get(
        `/statistics?filter[${filter}]=${value}`
      );
      commit("statistics", response);
    } catch (e) {}
  },
};

const getters = {
  currentTrackTimer: (state) => state.currentTrackTimer,
  currentSong: (state) => state.currentSong,
  player_mount: (state) => state.player_mount,
  station_id: (state) => state.station_id,
  player_status: (state) => state.player_status,
  player_audio: (state) => state.player_audio,
  statistics: (state) => state.statistics,
  statisticsMeta: (state) => state.statisticsMeta,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
