import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import interceptorSetup from "@/_http/index";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.config.productionTip = false;
interceptorSetup();

if (process.env.VUE_APP_SENTRY_ENVIROMENT !== "local") {
  Sentry.init({
    Vue,
    dsn: `https://58d9ca0b35804020994ed245140c49d5@${process.env.VUE_APP_SENTRY_DOMEN}/4`,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    logErrors: process.env.VUE_APP_SENTRY_LOG,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "localhost",
          "drh-frontpanel.projects.dl-net.ru",
          "listen.dline-media.com",
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
