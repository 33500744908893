var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-pagination__wrapper"},[_c('ul',{ref:"pagination",staticClass:"base-pagination"},[_c('li',{class:_vm.active - 1 < 1
          ? 'base-pagination__item base-pagination__iteration base-pagination__item--not-active'
          : 'base-pagination__item base-pagination__iteration',on:{"click":_vm.prevPage}},[_vm._v(" ‹ ")]),_vm._l((_vm.items),function(item,index){return _c('li',{key:'pagination-' + index,class:_vm.active === item
          ? 'base-pagination__item--active'
          : 'base-pagination__item',on:{"click":function($event){return _vm.select(item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),_c('li',{class:_vm.active + 1 > _vm.pages
          ? 'base-pagination__item base-pagination__iteration base-pagination__item--not-active'
          : 'base-pagination__item base-pagination__iteration',on:{"click":_vm.nextPage}},[_vm._v(" › ")])],2),_c('div',{staticClass:"base-pagination__per-page"},[_c('div',{staticStyle:{"margin-right":"10px"}},[_vm._v("Количество на странице")]),_c('div',{staticStyle:{"width":"80px"}},[_c('BaseSelect',{attrs:{"options":_vm.perPageOption,"field":'field',"title":'label',"options-top":""},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }