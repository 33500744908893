<template>
  <div class="player">
    <div class="control-play">
      <img @click="play" :class="play_class" :src="play_img" alt="" />
    </div>
    <div class="control-volume">
      <img @click="mute" src="../assets/img/player/volume.png" alt="" />
      <VolumePlayer v-model="volume" />
    </div>
    <marquee class="song-name" behavior="" direction="">{{
      currentSong
    }}</marquee>
  </div>
</template>

<script>
import VolumePlayer from "@/components/VolumePlayer";

export default {
  components: {
    VolumePlayer,
  },
  props: {
    mount: { type: String, default: "" },
    lastSong: String,
    streamId: Number,
    id: Number,
  },
  data() {
    return {
      lastSaveSong: null,
      save_volume: 50,
      volume: 50,
      isMute: false,
      host: process.env.VUE_APP_AUDIO_HOST + "/",
    };
  },
  created() {
    const session_volume = this.getVolume();
    if (session_volume) {
      this.volume = session_volume;
    }
  },
  watch: {
    volume(volume) {
      if (this.id === this.$store.getters.station_id) {
        if (volume < 1) {
          this.audio.volume = 0;
          this.audio.muted = true;
        } else {
          this.audio.volume = volume / 100;
          this.audio.muted = false;
        }
      }
      this.saveVolume(volume);
    },
    mount(newMount) {
      const currentMount = this.$store.getters.player_mount;
      if (currentMount !== newMount) {
        if (this.audio.currentTime > 0 || !this.audio.paused) {
          this.audio.pause();
        }
        this.$store.commit("player_status", "loading");
        // сохраняем маунт который мы вещаем
        this.$store.commit("player_mount", this.mount);
        this.$store.commit("station_id", this.id);
        // устанавливаем адрес источника радио
        this.audio.src = this.host + this.mount;
        this.audio.volume = this.volume / 100;
        // запрускаем проигрывание
        this.audio
          .play()
          .then(() => {
            this.$store.commit("player_status", "playing");
          })
          .catch((e) => {
            this.$store.commit("player_status", "pausing");
          });
      }
    },
  },
  computed: {
    currentSong() {
      if (this.id === this.$store.getters.station_id && this.$store.getters.player_status === 'playing') {
        this.lastSaveSong = this.$store.getters.currentSong;
        return this.$store.getters.currentSong;
      } else if (this.lastSaveSong){
        return this.lastSaveSong;
      } else {
        return this.lastSong;
      }
    },
    play_img() {
      if (this.id === this.$store.getters.station_id) {
        switch (this.$store.getters.player_status) {
          case "pausing":
            return require("@/assets/img/player/play.png");
          case "loading":
            return require("@/assets/img/player/loading.png");
          case "playing":
            return require("@/assets/img/player/pause.png");
        }
      } else {
        return require("@/assets/img/player/play.png");
      }
    },
    play_class() {
      if (
        this.$store.getters.player_status === "loading" &&
        this.id === this.$store.getters.station_id
      ) {
        return "player--loading";
      } else {
        return "";
      }
    },
    audio: {
      get() {
        return this.$store.getters.player_audio;
      },
      set(value) {
        return (this.$store.state.player_audio = value);
      },
    },
  },
  methods: {
    saveVolume(volume) {
      sessionStorage.setItem("volume-" + this.id, volume);
    },
    getVolume() {
      return parseInt(sessionStorage.getItem("volume-" + this.id) || "50");
    },
    play() {
      if (this.id === this.$store.getters.station_id) {
        switch (this.$store.getters.player_status) {
          // если плеер запущен то выключет аудио
          case "playing":
            this.$store.commit("currentSong", null);
            this.$store.commit("stream_id", null);
            this.$store.commit("player_status", "pausing");
            this.audio.pause();
            break;
          // если плеер остановлен то включет аудио
          case "pausing":
            if (this.audio.currentTime > 0 || !this.audio.paused) {
              this.audio.pause();
            }
            this.$store.commit("player_status", "loading");
            // сохраняем маунт который мы вещаем
            this.$store.commit("player_mount", this.mount);
            this.$store.commit("station_id", this.id);
            // устанавливаем адрес источника радио
            this.audio.src = this.host + this.mount;
            this.audio.volume = this.volume / 100;
            // запрускаем проигрывание
            this.audio
              .play()
              .then(() => {
                this.$store.commit("stream_id", this.streamId);
                this.$store.commit("player_status", "playing");
              })
              .catch((e) => {
                this.$store.commit("currentSong", null);
                this.$store.commit("stream_id", null);
                this.$store.commit("player_status", "pausing");
              });
            break;
          case "loading":
            this.$store.commit("currentSong", null);
            this.$store.commit("stream_id", null);
            this.$store.commit("player_status", "pausing");
            this.audio.pause();
            break;
        }
      } else {
        if (this.audio.currentTime > 0 || !this.audio.paused) {
          this.audio.pause();
        }
        this.$store.commit("player_status", "loading");
        // сохраняем маунт который мы вещаем
        this.$store.commit("player_mount", this.mount);
        this.$store.commit("station_id", this.id);
        // устанавливаем адрес источника радио
        this.audio.src = this.host + this.mount;
        this.audio.volume = this.volume / 100;
        // запрускаем проигрывание
        this.audio
          .play()
          .then(() => {
            this.$store.commit("stream_id", this.streamId);
            this.$store.commit("player_status", "playing");
          })
          .catch((e) => {
            this.$store.commit("stream_id", null);
            this.$store.commit("player_status", "pausing");
          });
      }
    },
    mute() {
      if (this.isMute) {
        this.isMute = false;
        this.volume = this.save_volume;
        this.audio.volume = 1;
        this.audio.muted = false;
      } else {
        this.isMute = true;
        this.save_volume = this.volume;
        this.volume = 0;
        this.audio.volume = 0;
        this.audio.muted = true;
      }
    },
  },
};
</script>

<style>
.player {
  background: #e9e9e9;
  position: relative;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #dcdcdc;
  display: grid;
  grid-template-columns: 40px 345px 1fr;
  grid-template-rows: 40px;
}
.player::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 40px;
  top: 0;
  left: 48px;
  background: #dcdcdc;
}
.player .control-play {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.player .control-play img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}
.player .control-play img:hover {
  cursor: pointer;
}
.player .control-volume {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.player .control-volume img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-left: 20px;
}
.player .control-volume img:hover {
  cursor: pointer;
}
.player .control-volume .volume {
  position: relative;
  height: 30px;
  margin-left: 20px;
}
.player .control-volume .volume .volume-total {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 1;
  height: 10px;
  width: 280px;
  background: #bababa;
  border-radius: 5px;
}
.player .control-volume .volume .volume-handle {
  position: absolute;
  top: 6px;
  left: 271px;
  z-index: 3;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #ffffff;
}
.player .control-volume .volume .volume-current {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 2;
  border-radius: 5px;
  height: 10px;
  width: 146px;
  background: #77b3df;
}
.player .song-name {
  display: flex;
  align-items: center;
  margin: 0 15px;
  color: #77b3df;
  font-weight: 500;
}
.resizable-conten {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background: red;
  z-index: 99;
  top: 0;
  left: 0;
}
.player--loading {
  animation: loading 0.5s linear infinite;
}
@media (max-width: 430px) {
  .player .control-volume .volume .volume-total {
    position: absolute;
    top: 12px;
    left: 0;
    z-index: 1;
    height: 6px;
    width: 110px;
    background: #bababa;
    border-radius: 3px;
  }
  .player .control-volume .volume .volume-handle {
    left: 101px;
  }
  .player {
    grid-template-columns: 40px 1fr;
  }
  .player .control-volume .volume {
    margin-left: 5px;
  }
}
@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
