<template>
  <div class="wrapper-x">
    <div class="wrapper">
      <Header />
      <div class="content">
        <section class="content">
          <div class="section_header">
            <h1>Мониторинг радио сервера</h1>
            <p>
              На этой странице отображается текущая статистика загрузки радио
              сервера компании DLine Media. Доступна такая информация, как:
              название радиостанции, описание потока, время запуска вещания,
              текущий битрейт, информация о текущем и максимальном количестве
              слушателей, информация о звучащем треке и др. Кроме того есть
              возможность послушать вещание радиостанции из браузера, либо
              скопировать ссылку на поток. Обратите внимание: это техническая
              страница и предназначена только для мониторинга состояния сервера!
            </p>
          </div>
          <div
            v-if="station_total > 1"
            style="display: flex; width: 100%; margin-bottom: 25px"
          >
            <Search
              :filter="filter"
              :filters="filters"
              placeholder="Поиск ..."
              @loading="value => loading = value"
              search_method="statistic_search"
            />
          </div>
          <station
            v-for="station in statistics"
            :data="station"
            :radio_station="station.name"
            :mount="station.name"
            :key="station.station_id"
          />
          <div v-if="!statistics.length && !loading" class="not-station">
            Нет данных
          </div>
          <preloader v-if="loading" />
        </section>
      </div>
      <Footer />
    </div>
    <notification />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Header from "@/components/Header";
import Notification from "@/components/Notification";
import Footer from "@/components/Footer";

import Station from "@/components/Station";
import Search from "@/components/Search";
import Preloader from "@/components/Preloader";

export default {
  name: "Home",
  components: {
    Pagination,
    Station,
    Preloader,
    Search,
    Header,
    Footer,
    Notification,
  },
  data() {
    return {
      loading: true,
      page: 1,
      station_total: 0,
      filter: {
        field: "station_name",
        label: "Название",
      },
      filters: [
        {
          field: "station_name",
          label: "Название",
        },
        {
          field: "streams.mount",
          label: "Mount",
        },
      ],
    };
  },
  created() {
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "#1A4E7C");

    this.$store
      .dispatch("statistics")
      .then((r) => {
        this.station_total = r.meta.total;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  mounted() {
    window.addEventListener("scroll", this.scrollTracking);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollTracking);
  },
  methods: {
    scrollTracking() {
      const meta = this.$store.getters.statisticsMeta;
      if (
        !this.loading &&
        meta.last_page !== meta.current_page &&
        window.scrollY + document.body.clientHeight >
          document.body.scrollHeight - document.body.clientHeight
      ) {
        this.loading = true;
        this.$store
          .dispatch("statistics", { page: meta.current_page + 1, concat: true })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  watch: {
    page(value) {
      this.$store.dispatch("statistics", {
        page: value,
      });
    },
  },
  computed: {
    meta() {
      return this.$store.getters.statisticsMeta;
    },
    statistics() {
      if (this.$store.getters.statistics?.length) {
        return this.$store.getters.statistics;
      } else {
        return [];
      }
    },
  },
};
</script>
<style lang="scss">
body {
  background: #1a4e7c;
}
.not-station {
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}
.wrapper-x {
  height: 100%;
  text-align: center;
  color: #2c3e50;
  max-width: 1200px;
  margin: 0 auto 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.content {
  flex: 1 0 auto;
}
footer {
  flex: 0 0 auto;
}
a {
  color: #81b6e5;
}
a:hover {
  color: #fff;
}

.content {
  margin: 35px 20px 0 20px;
  background: #fff;
  border-radius: 7px;
  padding: 20px;
}
.section_header {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 25px;
}
.section_header h1 {
  color: #184771;
  font-weight: 300;
  font-size: 36px;
  margin: 0;
}
.section_header p {
  color: #184771;
  font-weight: 700;
  font-size: 14px;
}
.section_header::after {
  content: "";
  min-width: 100%;
  height: 1px;
  background: #f0f0f0;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 720px) {
  .content {
    padding: 0;
  }
}
</style>
