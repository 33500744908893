<template>
  <div id="app">
		<!-- <div class="wrapper"> -->
		<!-- <Header /> -->
    <!-- <div class="content"> -->
      <router-view />
    <!-- </div> -->
		<!-- <Footer /> -->
		<!-- </div> -->
    <!-- <notification /> -->
  </div>
</template>

<script>
// import Header from "@/components/Header"
// import Notification from "@/components/Notification"
// import Footer from "@/components/Footer"
export default {
	components: {
		// Header,
		// Footer,
    // Notification,
	}
}
</script>
<style lang="scss">
@import url('//fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&display=swap');

#app {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	height: 100%;
}
html, body {
	margin: 0;
	padding: 0;
	height: 100%;
  width: 100%;
} 
/* .wrapper { */
/* 	display: flex; */
/* 	flex-direction: column; */
/* 	min-height: 100%; */
/* } */
/* .content { */
/* 	flex: 1 0 auto; */
/* } */
/* footer { */
/* 	flex: 0 0 auto; */
/* } */
/* a { */
/* 	color: #81b6e5; */
/* } */
/* a:hover { */
/* 	color: #fff; */
/* } */
</style>
