import Vue from "vue";
import Vuex from "vuex";
import statistics from "./statistics";
import notification from "./notification";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
		statistics,
    notification,
	}
});
