<template>
  <header>
    <div class="info">
      <img class="logo" src="../assets/img/logo.png" alt="Radio.DLine Media" />
      <div>
        <h2 class="title">{{ domain }}</h2>
        <div class="description">Информация о состоянии серверов</div>
      </div>
    </div>
    <div></div>
    <nav>
      <div class="nav-item"><a href="https://bill.dline-media.com" target="_blank">Биллинг</a></div>
      <div style="margin-left: 20px;" class="nav-item"><a href="https://dline-media.com/radio-hosting" target="_blank">О нас</a></div>
      <div style="margin-left: 20px;" class="nav-item"><a :href="lk_url" target="_blank">Управление</a></div>
    </nav>
    <div @click="activeMenu" class="mobile-nav">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
    <div class="mobile-menu" :class="{ active: mobActive }">
      <div @click="closeMenu" class="close-m-menu"></div>
      <div class="menu-content">
        <div class="nav-item">
          <a href="https://bill.dline-media.com" target="_blank">Биллинг</a>
        </div>
        <div class="nav-item"><a href="https://dline-media.com/radio-hosting" target="_blank">О нас</a></div>
        <div class="nav-item"><a :href="lk_url" target="_blank">Управление</a></div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      mobActive: false,
      lk_url: process.env.VUE_APP_CP,
      domain: window.location.hostname,
    };
  },
  methods: {
    activeMenu() {
      this.mobActive = !this.mobActive;
    },
    closeMenu() {
      this.mobActive = !this.mobActive;
    }
  }
};
</script>
<style>
.mobile-menu a {
  color: #fff;
}
.menu-content .nav-item {
  text-align: left;
}
.menu-content {
  margin: 110px 0 0 0;
  padding: 20px;
}
.menu-content .nav-item {
  font-size: 30px;
  margin: 20px;
	position: relative;
	padding-left: 20px;
}
.menu-content .nav-item:hover {
	opacity: 0.5;
}
.menu-content .nav-item::after {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	background: #fff;
	height: 6px;
	width: 6px;
	border-radius: 3px;
}
.menu-content a {
  text-decoration: none;
}
.mobile-nav {
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  width: 44px;
}
.mobile-nav:hover {
  cursor: pointer;
}
.mobile-nav .line {
  height: 4px;
  width: 100%;
  background: white;
  border-radius: 2px;
}
header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  margin: 0 20px;
  margin-top: 40px;
}
header .info {
  display: grid;
  grid-template-columns: 47px 1fr;
  grid-template-rows: auto;
  column-gap: 20px;
}
header .logo {
  max-width: 100%;
  height: auto;
}
header .title {
  color: #fff;
  font-size: 30px;
  text-align: left;
  margin: -7px 0 0 0;
}
header .description {
  color: #81b6e5;
  font-size: 14px;
  text-align: left;
  font-weight: 800;
}

nav {
  display: flex;
  /* column-gap: 20px; */
  align-items: center;
}
nav .nav-item {
  max-width: max-content;
}
nav .nav-item a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}
.mobile-menu {
  display: none;
}

.close-m-menu {
  position: fixed;
  right: 20px;
  top: 44px;
  width: 44px;
  height: 44px;
  transform: rotate(45deg);
}
.close-m-menu:hover {
  cursor: pointer;
}
.close-m-menu::after {
  content: "";
  height: 4px;
  width: 44px;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
}
.close-m-menu::before {
  content: "";
  height: 44px;
  width: 4px;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
}

@media (max-width: 800px) {
  header nav {
    display: none;
  }
  .mobile-nav {
    display: flex;
  }
  .mobile-menu.active {
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #1a4e7c;
    z-index: 99999;
  }
}
@media (max-width: 430px) {
  .menu-content .nav-item {
    font-size: 20px;
  }
  header .title {
    font-size: 15px;
    margin: 0;
  }
  header .description {
    font-size: 7.5px;
  }
  header .info {
    grid-template-columns: 40px 1fr;
  }
  .mobile-nav {
    width: 35px;
  }
  .close-m-menu::after {
    width: 35px;
  }
  .close-m-menu::before {
    height: 35px;
  }
  .close-m-menu {
    top: 35px;
  }
}
</style>
