<template>
  <div v-if="show" class="notification">
    <div class="notification__content">
      <SuccessIcon />
      <div class="notification__title" v-html="title"></div>
      <div class="notification__message" v-html="message"></div>
      <div class="notification__button" v-html="button" @click="close"></div>
    </div>
    <div class="notification__barier" @click="close"></div>
  </div>
</template>

<script>
import SuccessIcon from "@/components/Success";

export default {
  components: {
    SuccessIcon,
  },
  methods: {
    close() {
      this.$store.commit("notification", {
        show: false,
      });
    }
  },
  computed: {
    show() {
      return this.$store.getters.notification_show;
    },
    image() {
      return this.$store.getters.notification_image;
    },
    title() {
      return this.$store.getters.notification_title;
    },
    message() {
      return this.$store.getters.notification_message;
    },
    button() {
      return this.$store.getters.notification_button;
    },
  }
}
</script>

<style lang="scss" scoped>
.notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  &__image {
    margin-bottom: 20px;
  }
  &__title {
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-size: 18px;
  }
  &__content {
    padding: 20px;
    position: absolute;
    height: 260px;
    width: 260px;
    background: #fff;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
  }
  &__barier {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000002e;
  }
  &__button {
    color: #568cbc;
    padding: 3px 10px;
    margin: 0 auto;
    margin-top: 20px;
    width: max-content;
    border-radius: 8px;
    transition: all 0.4s ease;
    &:hover {
      cursor: pointer;
      background: #ebf2f7;
    }
  }
  &__message {
    margin-bottom: 10px;
  }
}
</style>
