const state = {
  notification_show: false,
  notification_image: '',
  notification_title: '',
  notification_message: '',
  notification_button: 'Хорошо'
};

const getters = {
  notification_show: state => state.notification_show,
  notification_image: state => state.notification_image,
  notification_title: state => state.notification_title,
  notification_message: state => state.notification_message,
  notification_button: state => state.notification_button
};

const mutations = {
  notification(state, data) {
    state.notification_image = data.image;
    state.notification_title = data.title;
    state.notification_message = data.message;
    state.notification_button = data.button;
    state.notification_show = data.show;
  },
};
export default {
  state,
  getters,
  mutations
};
