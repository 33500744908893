<template>
  <div class="volume-player">
    <div class="windowsSlider">
      <input
        v-model="percent"
        type="range"
        class="windowsSliderInput"
        min="0"
        max="100"
      />
      <div
        class="windowsSliderProgress"
        :style="{ width: percent + '%' }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Volume",
  props: {
    value: {
      type: [String, Number],
      default: 50,
    },
  },
  data() {
    return {
      temp: this.value,
      is_desktop: false,
    };
  },
  watch: {
    value(value) {
      this.temp = value;
    },
  },
  computed: {
    percent: {
      get() {
        return this.temp;
      },
      set(value) {
        this.temp = value;
        this.$emit("input", parseInt(value));
      },
    },
  },
};
</script>

<style lang="scss" scoped>

.volume-player {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.windowsSlider {
  height: 10px;
  width: 100%;
  border-radius: 5px;
  background: #bababa;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.windowsSliderInput {
  -webkit-appearance: none;
  background: transparent;
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  z-index: 2;
  margin-left: -9px;
  margin-right: -9px;
}
.windowsSliderInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow 0.5s;
  background: #fff;
}
.windowsSliderInput:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 2px #fff, 0 0 0 0px #fff;
}

.windowsSliderInput::-moz-range-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow 0.5s;
  background: #fff;
}
.windowsSliderInput:active::-moz-range-thumb {
  box-shadow: 0 0 0 2px #fff, 0 0 0 0px #fff;
}
.windowsSliderProgress {
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  border-radius: 5px;
  background: #77b3df;
  z-index: 1;
}
</style>
