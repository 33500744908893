<template>
  <div class="base-search__wrapper">
    <input
      class="base-search"
      type="text"
      :placeholder="placeholder"
      v-model="value"
      @input="change"
    />
    <ul class="base-search__filters">
      <li
        v-for="filter in filters"
        :key="filter.field"
        class="base-search__filter"
        :class="{
          'base-search__filter--active': filter.field === active_filter.field,
        }"
        @click="changeFilter(filter)"
      >
        {{ filter.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BaseSearch",
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    search_method: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "Поиск ...",
    },
  },
  data() {
    return {
      value: "",
      timerID: 0,
      active_filter: this.filter,
    };
  },
  methods: {
    changeFilter(filter) {
      this.active_filter = filter;
      if (this.value !== "") {
        this.$store.commit("statistics", {
          concat: false,
          data: {
            data: [],
            meta: {},
          },
        });
        this.$emit("loading", true);
        this.$store
          .dispatch(this.search_method, {
            filter: this.active_filter?.field
              ? this.active_filter.field
              : "station_name",
            value: this.value,
          })
          .finally(() => {
            this.$emit("loading", false);
          });
      }
    },
    change() {
      this.$emit("change", this.value);
      clearTimeout(this.timerID);
      this.timerID = setTimeout(() => {
        this.$store.commit("statistics", {
          concat: false,
          data: {
            data: [],
            meta: {},
          },
        });
        this.$emit("loading", true);
        this.$store
          .dispatch(this.search_method, {
            filter: this.active_filter?.field
              ? this.active_filter.field
              : "station_name",
            value: this.value,
          })
          .finally(() => {
            this.$emit("loading", false);
          });
      }, 600);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-search {
  border-radius: 20px;
  font-size: 16px;
  padding: 11px 20px;
  border: 1px solid #dcdcdc;
  outline: none;
  transition: all 0.45s ease;
  &:focus {
    border: 1px solid #1a4e7c;
  }
  &:focus-visible {
    border: 1px solid #1a4e7c;
  }
  &__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  &__filter {
    list-style: none;
    margin-left: 15px;
    font-size: 12px;
    font-weight: 700;
    opacity: 0.5;
    transition: all 0.3s ease;
    cursor: pointer;
    &--active {
      opacity: 1;
    }
    &s {
      padding-top: 5px !important;
      display: flex;
      margin: 0;
      padding: 0;
    }
  }
}
</style>
